.Pomodoro {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
}

.Pomodoro-logo {
  animation: Pomodoro-logo-spin infinite 15s normal;
  height: 40vmin;
  pointer-events: none;
}

@keyframes Pomodoro-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
